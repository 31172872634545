import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import style from "./style.module.scss";

const PROFILES = [
  {
    key: 0,
    name: "Giorgio aka Grug",
    role: "CEO / Game Designer",
    description:
      "Lead designer of Blob Arena & Rising Revenant. Game economy / Web3 expert.",
    image: require("assets/team/grugs.png"),
  },
  {
    key: 1,
    name: "Alex",
    role: "CTO / Lead Dev",
    description:
      "Principal developer of Blob Arena & Rising Revenant. Blockchain integration & game engine expert.",
    image: require("assets/team/alex.png"),
  },
  {
    key: 2,
    name: "Giacomo aka Sniper",
    role: "Creative Director",
    description:
      "Leads the creative vision and artistic direction. Oversees visual storytelling and user engagement strategies.",
    image: require("assets/team/sniper1821.png"),
  },
  {
    key: 3,
    name: "Ben",
    role: "Contract Engineer",
    description: "Blob Arena and Rising Revenant contract engineer.",
    image: require("assets/team/ben.jpeg"),
  },
  {
    key: 4,
    name: "Norbert",
    role: "Senior Game Developer",
    description: "Focuses on game physics, AI, and core mechanics.",
    image: require("assets/team/norbert.png"),
  },
  {
    key: 5,
    name: "Syn",
    role: "Product Manager",
    description: "Production, feature prioritization & team coordination.",
    image: require("assets/team/syn.png"),
  },
  {
    key: 6,
    name: "Romain",
    role: "UI Programmer",
    description: "Responsible for interactive UI/UX elements.",
    image: require("assets/team/romain.png"),
  },
  {
    key: 7,
    name: "Legolas",
    role: "Lead Artist",
    description:
      "Defines character & environment design, specializing in pixel art.",
    image: require("assets/team/legolas.png"),
  },
  {
    key: 8,
    name: "Mitch",
    role: "Marketing Director",
    description: "Strategy, partnerships and community growth.",
    image: require("assets/team/mitch.png"),
  },
];

const half = Math.ceil(PROFILES.length / 2);    
const PROFILES_FIRST_HALF = PROFILES.slice(0, half)
const PROFILES_SECOND_HALF = PROFILES.slice(half, PROFILES.length)

const Person = ({
  index,
  name,
  role,
  description,
  image,
  active,
  isMobile,
  onClick = () => null,
}) => {
  const descriptionHeight = useRef(0);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowDescription(active), 100);
  }, [active]);

  useEffect(() => {
    const descriptionTarget = document.getElementsByClassName(
      "the-team-collapsible-description"
    )[index];
    setTimeout(() => {
      descriptionHeight.current = descriptionTarget?.offsetHeight || 0;
      if (active) {
        setTimeout(() => setShowDescription(active), 100);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={twMerge(
        "cursor-pointer border-b border-transparent transition-colors duration-500",
        active && "border-primary500"
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-4 p-3">
        <img src={image} alt="avatar" className="size-20 rounded-full" />
        <div>
          <h2
            className={twMerge(
              "font-avara text-lg font-extrabold text-white",
              "smallDesktop:text-2xl"
            )}
          >
            {name}
          </h2>
          <p
            className={twMerge(
              "mt-1 font-avara text-sm font-bold text-white",
              "smallDesktop:text-lg"
            )}
          >
            {role}
          </p>
        </div>
      </div>
      <div
        className="overflow-hidden transition-all duration-1000"
        style={{
          height: showDescription ? descriptionHeight.current + 16 : 0,
        }}
      >
        {isMobile && (
          <p className="the-team-collapsible-description pt-[10px] font-sora text-sm font-light text-grayCool300">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

const TheTeam = () => {
  const isMobile = window.innerWidth < 1024;
  const clickLock = useRef(false);
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [displayedProfile, setDisplayedProfile] = useState(0);
  return !isMobile ? (
    <div
      id="the-team"
      data-scroll-section
      className="mt-20 flex w-full max-w-[1144px] flex-col items-center"
    >
      <h1 className="font-avara text-6xl font-extrabold text-white">
        The&nbsp;
        <span className="relative overflow-hidden whitespace-nowrap">
          <div
            data-scroll
            className={twMerge(
              style.the_team_title,
              "absolute -left-1 -top-2 h-full w-full",
              "tablet:-left-2"
            )}
          >
            <div
              className="absolute h-full w-[105%] bg-primary600"
              style={{
                transform:
                  "perspective(12px) rotate(2deg) rotateX(-1deg) rotateY(-0.2deg)",
              }}
            />
          </div>
          <span className="relative">Team</span>
        </span>
      </h1>
      <div className="mt-20 flex items-center gap-2 desktop:gap-4">
        <div
          className={twMerge(
            "w-[300px] transition-opacity duration-500 desktop:w-[448px]",
            selectedProfile === -1 && "opacity-0"
          )}
        >
          <img
            src={PROFILES.find(p => p.key === displayedProfile)?.image}
            alt="founder"
            className="size-[160px] rounded-full"
          />
          <h2 className="mt-[52px] font-avara text-4xl font-extrabold text-white">
            {PROFILES.find(p => p.key === displayedProfile)?.name}
          </h2>
          <p className="mt-1 font-avara text-2xl font-bold text-white">
            {PROFILES.find(p => p.key === displayedProfile)?.role}
          </p>
          <p className="mt-8 max-w-[348px] font-sora text-lg font-light text-grayCool300">
            {PROFILES.find(p => p.key === displayedProfile)?.description}
          </p>
        </div>
        <div
          className="flex w-[332px] flex-1 flex-col gap-4"
          data-scroll
          data-scroll-speed="-0.5"
        >
          {PROFILES_FIRST_HALF.map((data, i) => (
            <Person
              key={data.name}
              index={data.key}
              {...data}
              active={selectedProfile === data.key}
              onClick={() => {
                if (!clickLock.current) {
                  clickLock.current = true;
                  if (selectedProfile !== data.key) setSelectedProfile(-1);
                  setTimeout(() => {
                    setDisplayedProfile(data.key);
                    setSelectedProfile(data.key);
                    clickLock.current = false;
                  }, 500);
                }
              }}
            />
          ))}
        </div>
        <div
          className="flex w-[332px] flex-1 flex-col gap-4"
          data-scroll
          data-scroll-speed="0.5"
        >
          {PROFILES_SECOND_HALF.map((data, i) => (
            <Person
              key={data.name}
              index={data.key}
              {...data}
              active={selectedProfile === data.key}
              onClick={() => {
                if (!clickLock.current) {
                  clickLock.current = true;
                  if (selectedProfile !== data.key) setSelectedProfile(-1);
                  setTimeout(() => {
                    setDisplayedProfile(data.key);
                    setSelectedProfile(data.key);
                    clickLock.current = false;
                  }, 500);
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      id="the-team"
      data-scroll-section
      className="mt-20 flex w-full max-w-[1144px] flex-col items-center"
    >
      <h1 className="font-avara text-4xl font-extrabold text-white">
        The&nbsp;
        <span className="relative overflow-hidden whitespace-nowrap">
          <div
            data-scroll
            className={twMerge(
              style.the_team_title,
              "absolute -left-1 -top-1 h-full w-full",
              "tablet:-left-2"
            )}
          >
            <div
              className="absolute h-full w-[105%] bg-primary600"
              style={{
                transform:
                  "perspective(12px) rotate(2deg) rotateX(-1deg) rotateY(-0.2deg)",
              }}
            />
          </div>
          <span className="relative">Team</span>
        </span>
      </h1>
      <div className="mt-10 flex w-[332px] flex-col gap-4">
        {PROFILES.map((data, i) => (
          <Person
            key={data.name}
            index={data.key}
            {...data}
            active={selectedProfile === data.key}
            onClick={() => setSelectedProfile(data.key)}
            isMobile
          />
        ))}
      </div>
    </div>
  );
};

export default TheTeam;
