import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faImages, faPlay } from "@fortawesome/pro-regular-svg-icons";
import ReactPlayer from "react-player";

import Button from "components/Button";

import { openLink } from "utils";
import useMediaQuery from "hooks/useMediaQuery";

import blobArenaColosseum from "assets/images/blob-arena/blob-arena-colosseum.png";
import blobArenaAmma from "assets/images/blob-arena/blob-arena-amma.png";
import blobArenaCloud from "assets/images/blob-arena/blob-arena-cloud.png";
import blobArenaLogo from "assets/images/blob-arena/blob-arena-logo.png";
import blobArenaAmmaLogo from "assets/images/blob-arena/blob-arena-amma-logo.png";
import blobSword from "assets/images/blob-arena/blob-sword.png";
import blobShield from "assets/images/blob-arena/blob-shield.png";
import blobSwordAmma from "assets/images/blob-arena/blob-sword-amma.png";
import blobShieldAmma from "assets/images/blob-arena/blob-shield-amma.png";
import ammaLogo from "assets/images/blob-arena/amma-logo.png";
import clickMeBubble from "assets/images/blob-arena/click-me-bubble.gif";
import backToClassicBubble from "assets/images/blob-arena/back-to-classic-bubble.gif";
import realmsWorldLogo from "assets/images/realms-world.png";
import blobArenaAmmaPortrait from "assets/videos/blob-arena-amma-mobile.mp4";
import blobArenaAmmaLandscape from "assets/videos/blob-arena-amma.mp4";
import AudioBlobArenaAmma from "assets/audio/blob-arena-amma.mp3";

import style from "./style.module.scss";
import { faForwardStep, faVolumeXmark } from "@fortawesome/pro-solid-svg-icons";

const BlobArena = ({ onOpenGallery }) => {
  const ammaVideoRef = useRef();
  const audioBlobArenaAmma = useRef(new Audio(AudioBlobArenaAmma));

  const isHeightLargerThan960 = useMediaQuery("(min-height: 960px)");
  const isHeightLargerThan856 = useMediaQuery("(min-height: 856px)");
  const isHeightSmallerThan800 = useMediaQuery("(max-height: 800px)");
  const isHeightSmallerThan678 = useMediaQuery("(max-height: 678px)");

  const [ammaActivated, setAmmaActivated] = useState(false); // For dynamic rendering contents
  const [isTurningOnAmma, setIsTurningOnAmma] = useState(false); // For deciding should show video or text
  const [ammaModalOpen, setAmmaModalOpen] = useState(false); // For mounting video element
  const [ammaVideoMounted, setAmmaVideoMounted] = useState(false); // For fade in & out video element
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState(true);

  const openGallery = () => {
    onOpenGallery([
      require("assets/images/blob-arena/gallery/1.jpg"),
      require("assets/images/blob-arena/gallery/2.jpg"),
      require("assets/images/blob-arena/gallery/3.jpg"),
      require("assets/images/blob-arena/gallery/4.jpg"),
    ]);
  };

  const toggleAmmaVideo = (isStart) => {
    setAmmaVideoMounted(isStart);
    document.getElementById("NavBar").style.opacity = isStart ? 0 : 1;
    document.getElementById("NavBar").style.pointerEvents = isStart
      ? "none"
      : "unset";
    if (isStart) {
      window.locomotive?.stop();
      setTimeout(() => {
        setAmmaActivated((prev) => !prev);
      }, 500);
    } else {
      setAudioPlaying(false);
      window.locomotive?.start();
      setTimeout(() => {
        setAmmaModalOpen(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (ammaModalOpen) {
      const turnOnAmma = !ammaActivated;
      setIsTurningOnAmma(turnOnAmma);

      if (!turnOnAmma) {
        setTimeout(() => {
          toggleAmmaVideo(false);
        }, 3000);
      }

      setIsLandscape(window.innerWidth >= window.innerHeight);
      toggleAmmaVideo(true);
    }
    window.stopAmmaAudio = () => audioBlobArenaAmma.current.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ammaModalOpen]);

  return (
    <>
      {ammaModalOpen && (
        <div
          className={twMerge(
            style.blob_arena_amma_video,
            !ammaVideoMounted && "opacity-0"
          )}
        >
          {isTurningOnAmma ? (
            <>
              <ReactPlayer
                playing
                muted
                controls={false}
                width="100%"
                height="100%"
                className="pointer-events-none"
                url={
                  isLandscape ? blobArenaAmmaLandscape : blobArenaAmmaPortrait
                }
                ref={(ref) => (ammaVideoRef.current = ref)}
                onBuffer={() => {
                  if (audioPlaying) {
                    audioBlobArenaAmma.current.pause();
                  }
                }}
                onPause={() => {
                  const progress =
                    ammaVideoRef.current.getCurrentTime() /
                    ammaVideoRef.current.getDuration();
                  if (audioPlaying & (progress !== 1)) {
                    audioBlobArenaAmma.current.pause();
                  }
                }}
                onBufferEnd={() => {
                  if (audioPlaying) {
                    audioBlobArenaAmma.current.play();
                  }
                }}
                onStart={() => {
                  if (!audioPlaying) {
                    audioBlobArenaAmma.current.pause();
                    audioBlobArenaAmma.current.currentTime = 0;
                    audioBlobArenaAmma.current.play();
                    setAudioPlaying(true);
                  }
                }}
                onEnded={() => {
                  toggleAmmaVideo(false);
                }}
              />
              <div
                className={twMerge(
                  "absolute right-4 top-4 flex gap-2",
                  "smallDesktop:gap-4"
                )}
              >
                <Button
                  size={window.innerWidth >= 1024 ? "large" : "small"}
                  onClick={() => {
                    setAudioPlaying(false);
                    audioBlobArenaAmma.current.pause();
                  }}
                  className={twMerge(
                    "w-[60px] justify-center",
                    !audioPlaying && "hidden"
                  )}
                >
                  <FontAwesomeIcon icon={faVolumeXmark} className="text-xl" />
                </Button>
                <Button
                  size={window.innerWidth >= 1024 ? "large" : "small"}
                  onClick={() => {
                    audioBlobArenaAmma.current.currentTime = 18.3;
                    toggleAmmaVideo(false);
                  }}
                  className="w-[60px] justify-center"
                >
                  <FontAwesomeIcon icon={faForwardStep} className="text-xl" />
                </Button>
              </div>
            </>
          ) : (
            <h1 className="p-6 text-center font-avara text-4xl font-extrabold text-white">
              Just went to AMMA—sweaty, bruised, and victorious.
              <br />
              <br />
              Heading home now...
            </h1>
          )}
        </div>
      )}

      <div
        id="blob-arena-content"
        className={twMerge(
          style.blob_arena,
          "relative z-[1] h-screen w-full",
          ammaActivated && "amma-activated"
        )}
      >
        {!ammaActivated && (
          <>
            <img
              src={blobArenaCloud}
              alt="colosseum"
              className={twMerge(
                style.cloud,
                "large-cloud absolute object-contain object-top"
              )}
            />
            <img
              src={blobArenaCloud}
              alt="colosseum"
              className={twMerge(
                style.cloud,
                "absolute object-contain object-top"
              )}
            />
          </>
        )}
        <img
          src={ammaActivated ? blobArenaAmma : blobArenaColosseum}
          alt="colosseum"
          className="absolute h-full w-full object-cover object-top"
        />
        <div
          id="blob-arena-main-overlay"
          className={twMerge(
            "absolute z-[1] flex h-full w-full flex-col items-center justify-center bg-grugBlack bg-opacity-65 px-4",
            "smallDesktop:px-0"
          )}
        >
          <img
            src={ammaActivated ? blobArenaAmmaLogo : blobArenaLogo}
            alt="blob-arena"
            className={twMerge(
              "-mt-[25%] max-w-[250px]",
              "smallDesktop:mt-0 smallDesktop:w-[514px] smallDesktop:max-w-none",
              isHeightSmallerThan800 &&
                "smallDesktop:mt-0 smallDesktop:w-[350px]"
            )}
          />
          <p
            className={twMerge(
              "text-sora mt-4 max-w-[810px] text-center text-base font-light text-white",
              "smallDesktop:text-2xl"
            )}
          >
            {ammaActivated ? (
              <>
                Blob Arena AMMA takes turn-based combat to the next level. Bring
                the heat with AMMA-inspired tactics—sword strikes, shield
                punches and counterattacks.
                <br />
                <br />
                The cage is your battlefield—will you dominate or tap out?
              </>
            ) : (
              <>
                Blob Arena is where champions rise and fools fall. Forge your
                Blobert army, outplay your enemies, and claim your rightful
                place on the throne.
                <br />
                <br />
                <b className="font-extrabold">Early access pre-registration now open</b>
              </>
            )}
          </p>
          <div
            className={twMerge("z-[1] mt-8 flex gap-4", "smallDesktop:mt-10")}
          >
            <Button
              size={window.innerWidth >= 1024 ? "large" : "small"}
              onClick={() =>
                openLink("https://github.com/grugslair/blob-arena-contracts")
              }
              className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
            >
              <FontAwesomeIcon icon={faGithub} className="text-2xl" />
            </Button>
            <Button
              size={window.innerWidth >= 1024 ? "large" : "small"}
              onClick={() => openLink("https://blobarena.xyz?utm_source=grugslair.xyz")}
              className="whitespace-nowrap"
            >
              {/* <FontAwesomeIcon icon={faPlay} className="-mt-1 mr-2 text-lg" /> */}
              Get Your Spot
            </Button>
            <Button
              size={window.innerWidth >= 1024 ? "large" : "small"}
              onClick={openGallery}
              className="w-[60px] justify-center border border-white bg-[#0B0B0B33]"
            >
              <FontAwesomeIcon icon={faImages} className="text-2xl" />
            </Button>
          </div>
          <button
            className={twMerge(
              "mt-9 h-[120px] w-[120px]",
              isHeightSmallerThan800 && "mt-6",
              isHeightLargerThan856 && "mt-10 h-[150px] w-[150px]",
              isHeightLargerThan960 && "mt-12 h-[200px] w-[200px]"
            )}
            onClick={() => setAmmaModalOpen(true)}
          >
            <div className="relative">
              <img
                src={ammaActivated ? backToClassicBubble : clickMeBubble}
                alt="Click Me Bubble"
                className={twMerge(
                  "absolute h-[48px]",
                  ammaActivated
                    ? "-right-[72px] -top-[28px]"
                    : "-right-[44px] -top-[20px]"
                )}
                style={{ filter: "drop-shadow(5px 5px 5px #000)" }}
              />
              <img
                src={ammaActivated ? blobArenaLogo : ammaLogo}
                alt="AMMA Logo"
                style={{ filter: "drop-shadow(5px 5px 5px #000)" }}
              />
            </div>
          </button>
          <div
            className={twMerge(
              "absolute bottom-16 flex flex-col items-center gap-2",
              "smallDesktop:bottom-[3%]",
              isHeightSmallerThan678 &&
                "smallDesktop:ml-6 smallDesktop:self-start"
            )}
          >
            <div
              className="flex cursor-pointer items-center gap-2"
              onClick={() => openLink("https://realms.world/games/blob-arena")}
            >
              <p
                className={twMerge(
                  "text-sora text-sm font-light text-grayCool300",
                  "smallDesktop:text-xl"
                )}
              >
                Supported by:
              </p>
              <img
                src={realmsWorldLogo}
                alt="realms-world"
                className={twMerge("w-[74px]", "smallDesktop:w-[80px]")}
              />
            </div>
          </div>
        </div>
        <div
          className={twMerge(
            "pointer-events-none absolute bottom-[8%] z-[1] -ml-[15%] flex w-[130%]",
            "screen640:ml-0 screen640:w-full"
          )}
        >
          <div
            className={twMerge(
              style.floating_blob,
              "z-[1] -mr-[36px] flex flex-1 justify-end",
              "smallDesktop:-mr-[48px]",
              window.innerHeight >= 1200 && "smallDesktop:-mr-[68px]"
            )}
          >
            <div
              id="blob-arena-sword"
              className={twMerge(
                "relative w-[240px] min-w-[240px]",
                "smallDesktop:w-[320px] smallDesktop:min-w-[320px]",
                window.innerHeight >= 1200 &&
                  "smallDesktop:w-[480px] smallDesktop:min-w-[480px]"
              )}
            >
              <div
                className={twMerge(
                  style.blobShadow,
                  "-bottom-[16px] smallDesktop:-bottom-[20px]",
                  window.innerHeight >= 1200 && "smallDesktop:-bottom-[36px]"
                )}
              />
              <img
                src={ammaActivated ? blobSwordAmma : blobSword}
                alt="blob-sword"
                className="relative w-full object-contain"
              />
            </div>
          </div>

          <div
            className={twMerge(
              style.floating_blob,
              "right-blob -ml-[36px] flex flex-1 justify-start",
              "smallDesktop:-ml-[48px]",
              window.innerHeight >= 1200 && "smallDesktop:-ml-[68px]"
            )}
          >
            <div
              id="blob-arena-shield"
              className={twMerge(
                "relative w-[240px] min-w-[240px]",
                "smallDesktop:w-[320px] smallDesktop:min-w-[320px]",
                window.innerHeight >= 1200 &&
                  "smallDesktop:w-[480px] smallDesktop:min-w-[480px]"
              )}
            >
              <div className={twMerge(style.blobShadow, "bottom-[8px]")} />
              <img
                src={ammaActivated ? blobShieldAmma : blobShield}
                alt="blob-shield"
                className="relative w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlobArena;
